.container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
}

.headerStyle {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 66.67%;
    border-bottom: 3px solid #CBD0DC;
}

.logoStyle {
  padding: 10px;
}

.headerTextStyle {
  font-size: 34px;
  font-weight: bold;
}


.bodyStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
  padding-top: 4vh;
  flex-direction: column;
  width: 66.67%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.buttonStyle {
  width: 50%;
  border-radius: 8px;
  max-width: 400px;
}

.iframeContainer {
  margin-top: 2%;
  width: 100%;
  height: 100%;
}

.iframeStyle {
  border-radius: 8px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}

.dropdownStyle {
  width: 20%;
  min-width: 0;
  flex: 1;
}
