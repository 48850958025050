/* Footer CSS - Footer.css */
.link-text {
	font-size: 17px;
	color: #6c757d;
}

.link-text:hover {
	color: #007bff;
	cursor: pointer;
}



footer {
	background-color: #f8f9fa;
	padding-top: 4rem;
	padding-bottom: 4rem;
	padding-left: 10rem;
	margin-top: 5rem;
}

footer .logo {
	width: 100px;
	height: auto;
}

footer .text-muted {
	height: auto;
	width: auto;
	color: #6c757d;
}

footer .list-unstyled {
	padding-left: 0;
}

footer .list-unstyled li {
	margin-bottom: 0.5rem;
}

footer .list-unstyled a {
	text-decoration: none;
	color: #6c757d;
	font-size: 1rem;
}

footer .list-unstyled a:hover {
	color: #007bff;
}

footer h5 {
	font-size: 1.25rem;
	font-weight: bold;
}

footer .d-flex a {
	color: #495057;
}

footer .d-flex a:hover {
	color: #007bff;
}

footer .text-center {
	text-align: center;
}

footer .mt-3 {
	margin-top: 1.5rem;
}

footer .me-3 {
	margin-right: 1rem;
}

@media (max-width: 767px) {
	footer {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	footer h5 {
		font-size: 1.1rem;
	}

	footer .logo {
		width: 80px;
	}

	footer .text-muted {
		font-size: 0.875rem;
	}

	footer .d-flex {
		justify-content: center;
	}

	footer .list-unstyled li {
		font-size: 0.9rem;
	}
}