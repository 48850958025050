.tutorial-button {
    position: fixed; /* Fixed positioning */
    bottom: 20px; /* Adjust distance from the bottom */
    left: 20px; /* Adjust distance from the right */
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    z-index: 1000; /* Ensure it's on top of other content */
    border-radius: 50%;
  }
  
  .tutorial-button img {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    vertical-align: middle;
  }